:root {
    --background-color: #f6f6f6;
    --foreground-color: #1976d2;
    --foreground-color-2: #FFA500;
    --primary-text-color: #0a0a0a;
    --secondary-text-color: #ffffff;
    --shadow-color-1: #1976d2;
    --foreground-color-2-alpha: #bbdefb8e;
    --text-color-foreground-2: #0D47A1;
    --text-color-2-foreground-2: #6A1B9A;
    --text-color-3-foreground-2: #1976d2;

    --svg-color-1: #BBDEFB;
    --svg-color-2: #FFB74D;
    --svg-color-3: var(--foreground-color);
}

[data-theme="dark"] {
    --background-color: #0a0a0a;
    --foreground-color: #121212;
    --foreground-color-2: #33bbcf;
    --primary-text-color: #f6f6f6;
    --secondary-text-color: #0a0a0a;
    --shadow-color-1: purple;
    --foreground-color-2-alpha: #55555555;
    --text-color-foreground-2: #FFFF99;
    --text-color-2-foreground-2: #FFB74D;
    --text-color-3-foreground-2: #FFA500;

    --svg-color-1: #1e01326b;
    --svg-color-2: #f1b966cc;
    --svg-color-3: #4e4d62;
}