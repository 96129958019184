@import url("./colors.scss");

#audioplayer {
  width: 480px;
  height: 60px;
  margin: 50px auto;
  border: solid;
}

#pButton {
  height: 60px;
  width: 60px;
  border: none;
  float: left;
  outline: none;
}

#pButton img {
  width: 40px;
  height: 40px;
  margin: auto;
}

#timeline {
  width: 400px;
  height: 20px;
  margin-top: 20px;
  float: left;
  border-radius: 15px;
  background: var(--background-color);
}

#playhead {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-top: 1px;
  background: #666;
}


@media only screen and (max-width: 600px) {
  #audioplayer {
    width: 280px;
  }

  #timeline {
    width: 200px;
  }
}