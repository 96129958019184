@import url("./colors.scss");

@font-face {
    font-family: Manrope;
    src: url("../assets/fonts/otf/Manrope-Bold.otf");
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Manrope', sans-serif;
}

#main {
    overflow-x: hidden;
    background-color: var(--background-color);
    color: var(--primary-text-color);

    .content {
        padding: 0 0.5em;
        min-height: calc(100lvh - 7em);

        h2 {
            font-size: 2em;
        }

        h1:not(#home h1) {
            font-size: 2.5em;
        }
    }
}

#NavBar {
    position: relative;
    top: 0;
    z-index: 100;
}

.user-details {
    text-align: center;
}

.payments {
    width: 100%;
    display: flex;
    flex-direction: column;

    h2 {
        margin-right: auto;
    }

    .paypal {
        display: flex;
        margin-top: 5em;
        align-self: center;
        justify-content: center;
        align-items: center;
        width: clamp(280px, 60%, 1000px);

        .paypal-button-wrapper {
            display: flex;
            flex-direction: column;
            width: clamp(280px, 60%, 1000px);

            .paypal-choose-amount {
                display: flex;
                align-items: center;

                border: #fff 2px solid;
                border-radius: 2.5rem;
                // background-color: $component-bg-color-4;
                box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 1px rgba(0, 0, 0, .25);

                cursor: pointer;

                padding: 0.4em 0.6em;
                margin: 0.4em;
                transition: transform 400ms, scale 400ms, box-shadow 400ms;

                div {
                    font-size: 0.8em;
                }

                input {
                    background-color: transparent;
                    border: unset;
                    font-size: 1.2em;
                    max-width: 70%;
                    color: var(--primary-text-color);
                }

                span {
                    font-size: 0.6em;
                    justify-self: end;
                }
            }
        }
    }
}

.tokens {

    h2 {
        display: none;
    }

    .token-item {

        button:hover,
        label:hover {
            color: var(--foreground-color-2);
        }
    }
}

.template-editor {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;

    min-height: calc(100lvh - 8em);

    .options {
        grid-area: 1 / 1 / span 2 / span 1;
        padding: 0 1em;

        max-height: calc(100lvh - 4em);
        overflow-y: scroll;

        .options-sublevel {
            padding: 0 2em;
        }
    }

    .canvas {
        grid-area: 1 / 2 / span 1 / span 1;
        background-color: #c8dafc;
    }

    .buttons {
        display: flex;
        flex-direction: column;
        gap: 1em;

        grid-area: 2 / 2 / span 1 / span 1;

        padding: 1em;
    }
}


// color picker
.picker {
    position: relative;

    .swatch {
        width: 28px;
        height: 28px;
        border-radius: 8px;
        border: 3px solid #fff;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
        cursor: pointer;
    }

    .popover {
        position: absolute;
        top: calc(100% + 2px);
        right: 0;
        border-radius: 9px;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
        z-index: 10;
    }
}

.form-container-1 {
    margin: 2em auto;
    width: clamp(300px, 60%, 800px);
}

// hide scroll bar
/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}



// ====================================Media Query============================================================================================

@media screen and (max-width: 767px) {
    .template-editor {
        grid-template-columns: 1fr;
        grid-template-rows: auto;

        .canvas {
            grid-area: 1 / 1 / span 1 / span 1;
        }

        .options {
            grid-area: 2 / 1 / span 1/ span 1;
            max-height: unset;
        }

        .buttons {
            grid-area: 3 / 1 / span 1 / span 1;
        }
    }
}